import React from 'react'

import { Blocks } from '../../../../interfaces'
import BlockComponents, { componentMap } from '../../../blocks'
import {
  Content,
  BufferBrandingLink,
  BufferFooter,
  BufferBrandingMoreModal,
  Overlay,
  ModalSubHeading,
  ModalLink,
  ModalButtonWrapper,
} from './styles'
import { More, Copy, ArrowRight, Flag, MagicWand } from '@bufferapp/ui/Icon'
import Text from '@bufferapp/ui/Text'

const START_PAGE_MARKETING_URL_PB =
  'https://buffer.com/start-page?source=powered-by'

const START_PAGE_MARKETING_URL_MODAL =
  'https://buffer.com/start-page?source=modal'

const START_PAGE_REPORT_URL =
  'https://report.buffer.com/?source=start-page&start_page_url='

export const globalHandlers = [
  {
    className: 'action-modal-link',
    eventName: 'click',
    handler: onActionModal,
  },
  {
    className: 'overlay',
    eventName: 'click',
    handler: onOverlay,
  },
  {
    className: 'copy-link',
    eventName: 'click',
    handler: onCopy,
  },
]

function onActionModal(event: React.MouseEvent | React.KeyboardEvent): void {
  const container = (event.target as HTMLElement).closest('.footer')
  if (!container) return

  const overlay = container.getElementsByClassName('overlay')[0] as HTMLElement
  overlay.style.display = 'block'

  const modal = container.getElementsByClassName('more-modal')[0] as HTMLElement
  modal.style.display = 'block'
}

function onOverlay(event: React.MouseEvent | React.KeyboardEvent): void {
  const container = (event.target as HTMLElement).closest('.footer')
  if (!container) return

  const overlay = container.getElementsByClassName('overlay')[0] as HTMLElement
  overlay.style.display = 'none'

  const modal = container.getElementsByClassName('more-modal')[0] as HTMLElement
  modal.style.display = 'none'
}

function onCopy(
  event: React.MouseEvent | React.KeyboardEvent,
  domain?: string,
): void {
  navigator.clipboard.writeText(
    domain ? `${domain}.start.page` : window.location.href,
  )

  const container = (event.target as HTMLElement).closest('.footer')
  if (!container) return

  const copied = container.getElementsByClassName('copied')[0]
  copied.textContent = 'Start Page URL copied!'
}

/**
 *
 */
function Website({
  preview,
  blocks,
  pageId,
  domain,
  isCommunityReportingFlagEnabled,
}: {
  preview: boolean
  blocks: Blocks
  pageId?: string
  domain?: string
  isCommunityReportingFlagEnabled?: boolean
}): JSX.Element {
  return (
    <>
      <Content data-testid="content">
        {blocks.map((block: any, index) => {
          const componentName = componentMap[block.__typename]
          if (block.hidden || !componentName) return null
          const Component = (BlockComponents as any)[componentName].BlockView

          return (
            <Component
              block={block}
              pageId={pageId}
              preview={preview}
              key={index}
            />
          )
        })}
      </Content>
      <BufferFooter className="footer">
        <BufferBrandingLink
          href={START_PAGE_MARKETING_URL_PB}
          target="_blank"
          rel="noopener"
        >
          Powered By <span>Buffer</span>
        </BufferBrandingLink>
        {isCommunityReportingFlagEnabled && (
          <>
            <BufferBrandingLink
              href="#"
              rel="noopener"
              className="action-modal-link"
              onClick={onActionModal}
            >
              <More size="medium" />
            </BufferBrandingLink>

            <BufferBrandingMoreModal className="more-modal" preview={preview}>
              <Text type="h3">Share this Start Page 📢</Text>
              <ModalSubHeading type="p">
                Share this Start Page with your friends and followers!
              </ModalSubHeading>
              <ModalLink
                href="#"
                rel="noopener"
                className="copy-link"
                onClick={(event): void => onCopy(event, domain)}
              >
                <ModalButtonWrapper>
                  <Copy />
                  <Text type="p" className="copied">
                    Copy the link
                  </Text>
                </ModalButtonWrapper>
                <ArrowRight />
              </ModalLink>
              <ModalLink
                href={`${START_PAGE_REPORT_URL}${domain}.start.page`}
                target="_blank"
                rel="noopener"
              >
                <ModalButtonWrapper>
                  <Flag />
                  <Text type="p">Report this Start Page</Text>
                </ModalButtonWrapper>
                <ArrowRight />
              </ModalLink>
              <ModalLink
                href={START_PAGE_MARKETING_URL_MODAL}
                target="_blank"
                rel="noopener"
              >
                <ModalButtonWrapper>
                  <MagicWand />
                  <Text type="p">Create your own Start Page</Text>
                </ModalButtonWrapper>
                <ArrowRight />
              </ModalLink>
            </BufferBrandingMoreModal>
            <Overlay className="overlay" onClick={onOverlay}></Overlay>
          </>
        )}
      </BufferFooter>
    </>
  )
}

export default Website
