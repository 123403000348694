/**
 * NOTE: Adapted from: https://awik.io/determine-color-bright-dark-using-javascript/
 */
export default function isDarkColor(color: string) {
  let hexColor
  let r
  let g
  let b

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RBG --> Get R, G and B values.
    hexColor = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    )

    r = hexColor ? parseInt(hexColor[1]) : 0
    g = hexColor ? parseInt(hexColor[2]) : 0
    b = hexColor ? parseInt(hexColor[3]) : 0
  } else {
    if (color.match(/^linear-gradient/)) {
      // grab first color
      const hexColorsInGradient = color.match(/\b[0-9A-F]+\b/)
      const firstColor = hexColorsInGradient ? hexColorsInGradient[0] : 'FFFFFF'
      hexColor = parseInt(`0x${firstColor}`)
    } else {
      hexColor = parseInt(`0x${color.slice(1)}`)
    }
    // If HEX --> Convert it to RGB.
    r = (hexColor >> 16) & 255
    g = (hexColor >> 8) & 255
    b = hexColor & 255
  }

  // Equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  return hsp <= 170
}
