import React from 'react'

import { SubHeadingBlock } from '../../../../interfaces'
import { Wrapper } from './styles'

/**
 *
 */
export function BlockView({ block: { text } }: { block: SubHeadingBlock }) {
  return (
    <Wrapper alignment="center">
      <h2>{text}</h2>
    </Wrapper>
  )
}
