import React, { SyntheticEvent } from 'react'
import Text from '@bufferapp/ui/Text'

import { ImageLinkBlock } from '../../../../interfaces'
import getResizeURL from '../../../../shared/utils/getResizeUrl'
import getAbsoluteUrl from '../../../../shared/utils/getAbsoluteUrl'
import getFallbackImage from '../../../../shared/utils/getFallbackImage'
import { CF_WORKER_TRACKING_URL } from '../../../../constants'
import { StyledImage, Caption, Wrapper } from './styles'

export function BlockView({
  block: { media, caption, url, id },
  pageId,
}: {
  block: ImageLinkBlock
  pageId?: string
}): JSX.Element {
  const imageUrl = media?.url
    ? getResizeURL({
        baseUrl: media.url,
        width: 640,
      })
    : ''

  const ImageWithCaption = (
    <>
      <StyledImage
        src={imageUrl}
        alt={media?.altText}
        onError={(event: SyntheticEvent<HTMLImageElement, Event>): void =>
          getFallbackImage(event)
        }
      />
      {caption && (
        <Caption>
          <Text type="p">{caption}</Text>
        </Caption>
      )}
    </>
  )

  if (url && url !== '') {
    return (
      <Wrapper>
        <a
          href={getAbsoluteUrl(url)}
          ping={url ? `${CF_WORKER_TRACKING_URL}?pid=${pageId}&bid=${id}` : ''}
          target="_blank"
          rel="noopener noreferrer ugc"
        >
          {ImageWithCaption}
        </a>
      </Wrapper>
    )
  }

  return <Wrapper>{ImageWithCaption}</Wrapper>
}
