import React from 'react'

import Logo from '../../../../../../shared/components/Logo'
import { IHeaderView } from '../types'

import { Wrapper, ContentWrapper, Header, SubHeader } from './styles'

/**
 *
 */
function NoImageVertical({ logo, title, description, textColor }: IHeaderView) {
  const { image: logoImg, alt: logoAlt } = logo

  return (
    <Wrapper>
      {logoImg && <Logo url={logoImg} alt={logoAlt} width={80} height={80} />}
      <ContentWrapper>
        {title && (
          <Header type="h1" color={textColor}>
            {title}
          </Header>
        )}
        {description && (
          <SubHeader type="h2" color={textColor}>
            {description}
          </SubHeader>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

export default NoImageVertical
