import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { corners, border } = props.getStyles('VIDEO') || {}

    return css`
      border-radius: ${corners};
      border: ${border};
      width: 100%;
      box-sizing: border-box;
      position: relative;
      padding-bottom: 56.25%; /* 16:9 ratio */
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    `
  }}
`)
