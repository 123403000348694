export default function shortenText(text: string, MAX_SIZE: number) {
  if (!text) return null

  let result = text

  if (result.length > MAX_SIZE) {
    result = result.slice(0, MAX_SIZE).concat('...')
  }

  return result
}
