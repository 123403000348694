import styled, { css } from 'styled-components'
import {
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
  white,
} from '@bufferapp/ui/style/colors'
import Text from '@bufferapp/ui/Text'

import { IThemeContext, withTheme } from '../../../themes'

export const Content = styled.div`
  flex: 1 0 auto;
  width: 100%;
  & > * {
    margin-bottom: 16px;
  }
`

export const BufferFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
`

export const BufferBrandingLink = withTheme(styled.a<IThemeContext>`
  ${(props) => {
    const { textColor } = props.getStyles()

    return css`
      margin: 0px 4px;

      &:link,
      &:focus,
      &:visited,
      &:hover,
      &:active {
        color: ${textColor || grayDark};
      }

      span {
        text-decoration-line: underline;
      }
    `
  }}
`)

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  width: 100%;
  height: 100%;
  display: none;
`

export const BufferBrandingMoreModal = styled.div<{ preview: boolean }>`
  ${(props) => {
    return css`
      position: ${props.preview ? 'absolute' : 'fixed'};
      top: 25%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000;
      display: none;

      flex-direction: column;
      align-items: center;
      background-color: ${white};
      padding: 28px;
      width: 430px;
      color: ${grayDarker};

      :last-child {
        margin-bottom: 0;
      }
    `
  }}
`

export const ModalSubHeading = styled(Text)`
  margin: 4px 0px;
`

export const ModalLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid ${grayLight};
  border-radius: 6px;
  cursor: pointer;
  margin-top: 12px;
  text-decoration: none;
  color: ${grayDarker};

  :hover {
    background-color: ${grayLighter};
  }
`

export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${grayDarker};

  svg {
    color: ${grayDark};
  }

  & > * {
    margin-right: 8px;
  }
`
