export default function getAbsoluteUrl(url: string) {
  let result = url

  const hasPrefix = /^(http|https|mailto|tel|sms):/.test(url)

  if (!hasPrefix) {
    result = `http://${url}`
  }

  return result
}
