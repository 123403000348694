import styled, { css } from 'styled-components'
import { white } from '@bufferapp/ui/style/colors'

import { IThemeContext, withTheme } from '../../../../../themes'

import { Header as BaseHeader, SubHeader as BaseSubHeader } from '../styles'

export const Background = withTheme(styled.div<
  { url: string | undefined } & IThemeContext
>`
  ${(props) => {
    const { corners, border } = props.getStyles('HEADER') || {}

    return css`
      border-radius: ${corners};
      border: ${border};
      height: 530px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
        ${props.url
          ? `url(${props.url})`
          : 'linear-gradient(0deg, #E6B333, #E6B333), #C4C4C4'};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
    `
  }}
`)

export const Header = styled(BaseHeader)`
  color: ${white};
  font-size: 55px;
  line-height: 55px;
  font-weight: 700;
`

export const SubHeader = styled(BaseSubHeader)`
  color: ${white};
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
`
