import React from 'react'

import { useThemeContext } from '../../../themes'
import { HeaderBlock } from '../../../../interfaces'
import isDarkColor from '../../../../shared/utils/isDarkColor'
import * as Layouts from './layouts'

export function BlockView({
  block: { title, description, backgroundMedia, logoMedia, layout },
}: {
  block: HeaderBlock
}) {
  const { getStyles } = useThemeContext()
  const { backgroundColor } = getStyles()

  const HeaderView =
    layout && (Layouts as any)[layout]
      ? (Layouts as any)[layout]
      : Layouts.ImageFullVertical

  const darkBackground = isDarkColor(backgroundColor)

  return (
    <HeaderView
      logo={{
        image: logoMedia?.url,
        alt: logoMedia?.altText || 'Logo image',
      }}
      backgroundImg={backgroundMedia?.url || null}
      title={title}
      description={description}
      textColor={darkBackground ? '#FFFFFF' : '#232323'}
    />
  )
}
