import styled from 'styled-components'
import { white, yellow } from '@bufferapp/ui/style/colors'

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
`

export const Placeholder = styled.div`
  width: 100%;
  aspect-ratio: 1;
  background-color: #dfdfdf;
`

export const Link = styled.a`
  width: 100%;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: cover;
`

export const EmptyWrapper = styled.div`
  position: relative;
`

export const Message = styled.div`
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${white};
  padding: 30px 14px;
  border-radius: 9px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  svg {
    fill: ${yellow};
    color: ${yellow};
    margin-bottom: 10px;
  }
`

export const MessageTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700px;
  margin-bottom: 8px;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400px;
`
