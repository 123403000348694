import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { ServerStyleSheet } from 'styled-components'
import parserHtml from 'prettier/parser-html'
import prettier from 'prettier'

import { Block, Blocks, HeaderBlock } from '../../interfaces'
import { Public } from '../../modules/views'
import { globalHandlers } from '../../modules/views/components/Website'
import BlockComponents, { componentMap } from '../../modules/blocks'
import getResizeURL from './getResizeUrl'
import getFonts from './getFonts'

export const AVAILABLE_FONTS: { [index: string]: string } = {
  'Bowlby One, sans-serif': 'Bowlby+One',
  'Playfair Display, serif': 'Playfair+Display',
  'Montserrat, sans-serif': 'Montserrat',
  'Source Sans Pro, sans-serif': 'Source+Sans+Pro',
  'Oswald, sans-serif': 'Oswald',
  'Poppins, sans-serif': 'Poppins',
  'Roboto Slab, serif': 'Roboto+Slab',
  'Quicksand, sans-serif': 'Quicksand',
  'Inter, sans-serif': 'Inter',
  'Libre Baskerville, serif': 'Libre+Baskerville',
  'Raleway, sans-serif': 'Raleway',
  'Cardo, serif': 'Cardo',
  'Amatic SC, cursive': 'Amatic+SC',
  'Vollkorn, serif': 'Vollkorn',
  'Lora, serif': 'Lora',
}

/**
 *
 */
export function getFontsFromTheme(theme: any): string {
  const fontsUsed = getFonts(theme.blockStyles)

  const links = fontsUsed
    .map((font: string) => {
      if (!AVAILABLE_FONTS[font]) return null
      return `family=${AVAILABLE_FONTS[font]}:wght@400;700&`
    })
    .filter((link) => link !== null)

  const familiesTogether = links.join('')
  return `<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&${familiesTogether}display=swap" rel="stylesheet">`
}

/**
 *
 */
type HandlerType = {
  className: string
  eventName: string
  handler: (event: any) => void
}

function generateScript(blocks: Blocks): string {
  const allHandlers: HandlerType[] = []
  const blockTypes: string[] = []

  globalHandlers.forEach((handler: HandlerType) => {
    allHandlers.push(handler)
  })

  // We make sure we only include the behavior of each
  // block type once.
  blocks.forEach((block: Block) => {
    const blockType = block.__typename
    if (!blockTypes.includes(blockType)) {
      blockTypes.push(blockType)
    }
  })

  blockTypes.push('StartPageBlockFeedPost')

  blockTypes.forEach((blockType: string) => {
    const componentName = componentMap[blockType]
    if (!componentName) return

    const { handlers } = (BlockComponents as any)[componentName]
    if (!handlers) return

    allHandlers.push(...handlers)
  })

  return allHandlers
    .map((element: any) => {
      const { className, eventName, handler } = element
      return `
      document.querySelectorAll('.${className}').forEach(element => {
        element.addEventListener('${eventName}', ${handler});
      });
    `
    })
    .join(';')
}

/**
 *
 */
function generateHTML({
  blocks,
  theme,
  pageId,
  domain,
  isCommunityReportingFlagEnabled,
}: {
  blocks: Blocks
  theme: any
  pageId?: string
  domain: string
  isCommunityReportingFlagEnabled?: boolean
}): string {
  const sheet = new ServerStyleSheet()

  const html = ReactDOMServer.renderToStaticMarkup(
    sheet.collectStyles(
      <Public
        blocks={blocks}
        theme={theme}
        pageId={pageId}
        domain={domain}
        isCommunityReportingFlagEnabled={isCommunityReportingFlagEnabled}
      />,
    ),
  )

  const styleTags = sheet.getStyleTags()
  sheet.seal()
  const fonts = getFontsFromTheme(theme)

  const header = blocks[0] as HeaderBlock
  const headerImage = header?.backgroundMedia?.url

  const headerImageUrl = headerImage
    ? getResizeURL({
        baseUrl: headerImage,
        height: 460,
      })
    : null

  // found here: https://stackoverflow.com/questions/6234773/can-i-escape-html-special-chars-in-javascript
  const escapeHtml = (unsafe: string | undefined): string | undefined =>
    unsafe &&
    unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  const htmlWDoc = `
    <!DOCTYPE html>
      <html>
        <head>
           <!-- Start cookieyes banner --> <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/15db6f01f9900312a5a9626f/script.js"></script> <!-- End cookieyes banner -->
          <title>${escapeHtml(header.title) || 'Start Page'}</title>
          <meta charset="UTF-8">
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="twitter:card" content="summary_large_image">
          <meta property="og:url" content="${domain}.start.page" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="${
            escapeHtml(header.title) || 'Start Page'
          }" />
          <meta property="og:description" content="${
            escapeHtml(header.description) || ''
          }" />
          <meta property="og:image" content="${header.logoMedia?.url}" />
          <meta property="og:image:alt" content="${
            escapeHtml(header.logoMedia?.altText) || ''
          }" />
          <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
          <link rel="preconnect" href="https://fonts.googleapis.com">
          ${fonts}
          <link rel="icon" type="image/svg+xml" href="https://buffer-start-page.s3.amazonaws.com/favicon.svg"/>
          <link rel="apple-touch-icon-precomposed" sizes="57x57" href="https://buffer-start-page.s3.amazonaws.com/apple-touch-icon-256x256.png" />
          ${
            headerImageUrl
              ? `<link rel="preload" href="${headerImageUrl}" as="image">`
              : ''
          }
          <style>
            html, body {
              font-family: Roboto, sans-serif;
              min-height: 100%;
              height: 100%;
              margin: 0px;
            }
          </style>
          ${styleTags}
        </head>
        <body>
          ${html}
          <script>
            ${generateScript(blocks)}
          </script>
        </body>
      </html>
    `

  const prettyHtml = prettier.format(htmlWDoc, {
    parser: 'html',
    plugins: [parserHtml],
  })

  return prettyHtml
}

export default generateHTML
