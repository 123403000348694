import React from 'react'
import styled from 'styled-components'

import { Blocks } from '../../../../interfaces'
import { IThemeContext, ThemeProvider, withTheme } from '../../../themes'
import Website from '../Website'

/**
 *
 */
const Wrapper = withTheme(styled.div<IThemeContext>`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  position: relative;
  background: ${(props) => props.getStyles().backgroundColor};

  & > * {
    max-width: 640px;
  }
`)

/**
 *
 */
function PublicContent({
  blocks,
  theme,
  pageId,
  domain,
  isCommunityReportingFlagEnabled,
}: {
  blocks: Blocks
  theme: any
  pageId?: string
  domain?: string
  isCommunityReportingFlagEnabled?: boolean
}): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Website
          blocks={blocks}
          pageId={pageId}
          preview={false}
          domain={domain}
          isCommunityReportingFlagEnabled={isCommunityReportingFlagEnabled}
        />
      </Wrapper>
    </ThemeProvider>
  )
}

export default PublicContent
