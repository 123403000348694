import styled from 'styled-components'

import { Header as BaseHeader, SubHeader as BaseSubHeader } from '../styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0px 32px 0px;
  width: 100%;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  margin: 0px 12px;
  flex: 1;
`

export const Header = styled(BaseHeader)`
  color: ${(props) => props.color};
  margin: 0px 0px 12px;
  text-align: left;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
`

export const SubHeader = styled(BaseSubHeader)`
  color: ${(props) => props.color};
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`
