export const RESIZE_SERVICE_URL = 'https://start-page.buffer.com/cdn-cgi/image'

export default function getResizeURL({
  baseUrl,
  width,
  height,
  fit,
}: {
  baseUrl: string
  width?: number
  height?: number
  fit?: string
}) {
  const options: string[] = []

  // NOTE: We multiply by '2' to make images look good on
  // retina screens.

  if (width) {
    options.push(`width=${width * 2}`)
  }

  if (height) {
    options.push(`height=${height * 2}`)
  }

  if (fit) {
    options.push(`fit=${fit}`)
  }

  return `${RESIZE_SERVICE_URL}/${options.join()}/${baseUrl}`
}
