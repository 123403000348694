import { BlockStyles } from '../../interfaces'

export default function getFonts(blocks: BlockStyles[]) {
  const fontsUsed: string[] = []

  const updateFonts = (fontFamily: string) => {
    const isIncludedAlready = fontsUsed.includes(fontFamily)
    if (!isIncludedAlready) fontsUsed.push(fontFamily)
  }

  blocks.forEach((block: BlockStyles) => {
    if (!block) return
    const { fontFamily, secondaryFontFamily } = block
    if (fontFamily) updateFonts(fontFamily)
    if (secondaryFontFamily) updateFonts(secondaryFontFamily)
  })

  return fontsUsed
}
