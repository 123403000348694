/* eslint-disable react/no-array-index-key */
import React from 'react'

import { FeedBlock } from '../../../../interfaces'
import { BlockView as FeedPost } from '../FeedPost'

import {
  PlaceholderWrapper,
  PostPlaceholder,
  PlaceholderMessage,
  Wrapper,
  Title,
} from './styles'

/**
 *
 */
function FeedPlaceholder({ title }: { title: string }): JSX.Element {
  return (
    <PlaceholderWrapper>
      {[...Array(3).keys()].map((_, index) => (
        <PostPlaceholder key={index} />
      ))}

      <PlaceholderMessage>
        <p>
          <b>{title || 'Updates'}</b>
        </p>
        <p>
          This section will be visible once updates have been posted, this is
          not visible on your public page right now.
        </p>
      </PlaceholderMessage>
    </PlaceholderWrapper>
  )
}

/**
 *
 */
export function BlockView({
  block,
  preview = false,
  pageId,
}: {
  block: FeedBlock
  preview: boolean
  pageId?: string
}): JSX.Element {
  const { children } = block || {}

  const emptyChildren = children?.length === 0
  const showPlaceholder = preview && emptyChildren

  return (
    <>
      {showPlaceholder && <FeedPlaceholder title={block.title} />}

      {!emptyChildren && (
        <Wrapper>
          <Title>{block.title || 'Updates'}</Title>
          {children?.slice(0, 5).map((blockData, index) => (
            <FeedPost
              block={blockData}
              preview={preview}
              key={index}
              pageId={pageId}
            />
          ))}
        </Wrapper>
      )}
    </>
  )
}

BlockView.defaultProps = {
  pageId: 'pageId',
}
