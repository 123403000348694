import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { backgroundColor } = props.getStyles('FEED_POST') || {}

    return css`
      padding: 16px;
      background-color: ${backgroundColor};
      display: flex;
      flex-direction: column;
      gap: 12px;
    `
  }}
`)

export const ContentWrapper = styled.div``

export const Image = styled.img`
  width: 30%;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 4px;
  float: right;
  margin: 0 0 8px 16px;
`

export const Text = withTheme(styled.p<IThemeContext>`
  ${(props) => {
    const { fontFamily, textColor } = props.getStyles('FEED_POST') || {}

    return css`
      display: block;
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: ${textColor};
      user-select: none;
      text-align: left;
      hyphens: none;
      padding: 0;
      margin: 0;
      font-family: ${fontFamily};

      &.hidden {
        display: none;
      }
    `
  }}
`)

export const Link = withTheme(styled.div<IThemeContext>`
  a {
    ${(props) => {
      const { fontFamily, corners, textColor, secondaryBackgroundColor } =
        props.getStyles('FEED_POST') || {}

      return css`
        border-radius: ${corners};
        background-color: ${secondaryBackgroundColor};
        padding: 12px;
        color: ${textColor};
        text-decoration: none;
        font-weight: 400;
        font-size: 15px;
        font-family: ${fontFamily};
        line-height: 18px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
          margin: 0px 8px;
        }
      `
    }}
`)

export const Date = withTheme(styled.p<IThemeContext>`
  ${(props) => {
    const { fontFamily, secondaryTextColor } =
      props.getStyles('FEED_POST') || {}

    return css`
      font-family: ${fontFamily};
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      color: ${secondaryTextColor};
      margin: 0px 0px 8px;
      text-align: left;
    `
  }}
`)

export const ReadMore = withTheme(styled.a<IThemeContext>`
  ${(props) => {
    const { fontFamily, secondaryTextColor } =
      props.getStyles('FEED_POST') || {}

    return css`
      font-family: ${fontFamily};
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
      color: ${secondaryTextColor};
      text-decoration: none;
      margin-left: 4px;
      cursor: pointer;
      white-space: nowrap;
    `
  }}
`)
