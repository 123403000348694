import React from 'react'

import getResizeURL from '../../../../../../shared/utils/getResizeUrl'
import Logo from '../../../../../../shared/components/Logo'
import { IHeaderView } from '../types'
import { Background, Header, SubHeader } from './styles'

/**
 *
 */
function ImageFullVertical({
  backgroundImg,
  logo,
  title,
  description,
}: IHeaderView) {
  const { image: logoImg, alt: logoAlt } = logo

  const backgroundImgResized = backgroundImg
    ? getResizeURL({
        baseUrl: backgroundImg,
        height: 530,
      })
    : null

  return (
    <Background url={backgroundImgResized}>
      {logoImg && <Logo url={logoImg} alt={logoAlt} width={97} height={97} />}
      {title && <Header type="h1">{title}</Header>}
      {description && <SubHeader type="h2">{description}</SubHeader>}
    </Background>
  )
}

export default ImageFullVertical
