import styled, { css } from 'styled-components'
import { fontWeight } from '@bufferapp/ui/style/fonts'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<
  {
    alignment: 'left' | 'center' | 'right'
  } & IThemeContext
>`
  ${(props) => {
    const { textColor, backgroundColor, corners, border, fontFamily } =
      props.getStyles('TEXT') || {}

    return css`
      background-color: ${backgroundColor};
      border-radius: ${corners};
      border: ${border};
      text-align: ${props.alignment};
      width: 100%;
      padding: 22px;
      box-sizing: border-box;

      p {
        color: ${textColor};
        font-family: ${fontFamily};
        font-size: 16px;
        font-style: normal;
        font-weight: ${fontWeight};
        line-height: 24px;
        letter-spacing: 0px;
        margin: 0;

        &.slate-p {
          text-align: left;
        }

        &.slate-align-center {
          text-align: center;
        }

        &.slate-align-right {
          text-align: right;
        }
      }

      pre {
        color: ${textColor};
        font-family: ${fontFamily};
        font-size: 16px;
        font-style: normal;
        font-weight: ${fontWeight};
        line-height: 24px;
        letter-spacing: 0px;
        margin: 0;
        white-space: pre-line;
      }
    `
  }}
`)
