import React, { useContext } from 'react'

import { IStartPageTheme, BlockStyles } from '../../../interfaces'

/**
 *
 */
interface IThemeContext {
  getStyles: (blockType?: string) => BlockStyles
}

const ThemeContext = React.createContext<IThemeContext | undefined>(undefined)

/**
 * On the theme data "blockStyles" is an array of objects, one per block type.
 * This function converts that array into a map so we can access block
 * styles by using the "block type" as a key.
 */
const getMappedTheme = (theme: any) => {
  if (!theme) return null

  const { id, globalStyles, blockStyles } = theme

  const blockStylesMap = blockStyles.reduce((map: any, styles: BlockStyles) => {
    if (styles) {
      map[styles.blockType] = { ...styles }
    }

    return map
  }, {})

  return {
    id,
    globalStyles,
    blockStyles: blockStylesMap,
  }
}

/**
 *
 */
function ThemeProvider({
  theme,
  children,
}: {
  theme: IStartPageTheme | undefined
  children: React.ReactNode
}) {
  const currentTheme = getMappedTheme(theme)

  const getStyles = (blockType?: string) => {
    const currentStyles = blockType
      ? currentTheme?.blockStyles[blockType]
      : currentTheme?.globalStyles

    return currentStyles
  }

  return (
    <ThemeContext.Provider value={{ getStyles }}>
      {children}
    </ThemeContext.Provider>
  )
}

/**
 *
 */
function useThemeContext() {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext is undefined')
  }
  return context
}

export { ThemeProvider, useThemeContext, IThemeContext }
