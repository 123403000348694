import React from 'react'

import { SpotifyBlock } from '../../../../interfaces'
import { getSpotifyEmbed } from '../../../../shared/utils/getSpotifyEmbed'
import { Wrapper, Embed } from './styles'

/**
 *
 */
export const BlockView = ({ block: { media } }: { block: SpotifyBlock }) => {
  try {
    const spotifyEmbed = media?.url && getSpotifyEmbed(media?.url)
    const { type, url } = spotifyEmbed || {}

    return (
      <Wrapper>
        <Embed type={type} src={url} frameBorder="0" allow="encrypted-media" />
      </Wrapper>
    )
  } catch (error) {
    return null
  }
}
