import styled, { css } from 'styled-components'
import { gray } from '@bufferapp/ui/style/colors'
import { fontWeight } from '@bufferapp/ui/style/fonts'

export const Link = styled.div<{
  customStyles: {
    backgroundColor: string
    textColor: string
    corners: string
    border: string
    fontFamily: string
  }
}>`
  a {
    ${(props) => css`
      background-color: ${props.customStyles.backgroundColor};
      border-radius: ${props.customStyles.corners};
      color: ${props.customStyles.textColor};
      border: ${props.customStyles.border};
      font-family: ${props.customStyles.fontFamily};
      padding: 24px;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      text-decoration: none;
      width: 100%;
      justify-content: center;
      font-size: 16px;
      line-height: 24px;
      font-weight: ${fontWeight};
    `}
  }
`

export const ImageWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ImagePlaceholder = styled.div`
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #dedede, #dedede), #c4c4c4;

  svg {
    fill: ${gray};
  }
`
