import styled, { FlattenSimpleInterpolation, css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<
  {
    alignment: 'left' | 'center' | 'right'
  } & IThemeContext
>`
  ${(props): FlattenSimpleInterpolation => {
    const { textColor, backgroundColor, corners, border, fontFamily } =
      props.getStyles('MAILCHIMP') || {}

    const {
      textColor: buttonTextColor,
      backgroundColor: buttonBackgroundColor,
    } = props.getStyles('BUTTON_LINK')

    return css`
      color: ${textColor};
      background-color: ${backgroundColor};
      border-radius: ${corners};
      border: ${border};
      font-family: ${fontFamily};
      text-align: ${props.alignment};
      width: 100%;
      padding: 30px 20px;
      box-sizing: border-box;
      line-height: 1.4;

      /* Generic tags */
      h2 {
        font-family: ${fontFamily};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        margin: 0;
      }

      label {
        font-size: 14px;
        font-weight: bold;

        + input {
          display: block;
        }
      }

      fieldset {
        border: none;
      }

      a {
        color: ${textColor};
      }

      li {
        list-style: none;
      }

      input,
      select {
        height: 30px;
        border-radius: 4px;
        border: 1px solid #b2b2b2;
      }

      /* Specific input types */
      input[type='email'],
      input[type='text'],
      input[type='url'],
      input[type='imageurl'] {
        width: 90%;
        padding: 5px 10px;
      }
      input[type='checkbox'] {
        width: 16px;
        height: 16px;
        color: black;
      }
      input[type='number'],
      input[type='zip'] {
        width: 60px;
        padding: 3px;
      }
      input[type='radio'] {
        height: 15px;

        + label {
          padding-left: 5px;
        }
      }
      input.datepart[type='text'],
      input.birthday[type='text'] {
        width: 30px;
        padding: 3px;
      }

      /* Subscribe button */
      input[type='submit'] {
        background-color: ${buttonBackgroundColor};
        height: 40px;
        width: 100%;
        margin-top: 20px;
        color: ${buttonTextColor};
        font-weight: 700;
        font-size: 16px;
        border-radius: ${corners};
        border: none;
        padding: 10px 25px;
        cursor: pointer;
      }

      /* Specific sections */
      div.mc-field-group {
        padding: 5px 0;
      }
      div.indicates-required {
        margin-bottom: 20px;
      }
      div#mergeRow-gdpr {
        margin: 30px 0 10px 0;
      }

      fieldset.mc_fieldset.gdprRequired.mc-field-group {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px;

        label span {
          font-size: 14px;
          padding: 0 5px;
          vertical-align: top;
        }
      }

      .content__gdprLegal {
        margin-top: 10px;
      }

      /* Mailchimp brand */
      p.brandingLogo {
        text-align: center;
      }
      img {
        margin-top: 20px;
        width: 200px;
        text-align: middle;
      }

      .optionalParent p {
        text-align: center;
      }
    `
  }}
`)
