import styled, { css } from 'styled-components'

import Logo from '../../../../../../shared/components/Logo'
import { IThemeContext, withTheme } from '../../../../../themes'

import { Header as BaseHeader, SubHeader as BaseSubHeader } from '../styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const Background = withTheme(styled.img<IThemeContext>`
  ${(props) => {
    const { corners, border } = props.getStyles('HEADER') || {}

    return css`
      border-radius: ${corners};
      border: ${border};
      max-width: 100%;
      height: 200px;
      object-fit: cover;
    `
  }}
`)

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
`

export const Header = styled(BaseHeader)`
  color: ${(props) => props.color};
  margin: 12px 0px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
`

export const SubHeader = styled(BaseSubHeader)`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`

export const StyledLogo = withTheme(styled(Logo)<IThemeContext>`
  ${(props) => {
    const borderColor = props.getStyles().backgroundColor

    return css`
      border: 4px solid ${borderColor};
      background-color: ${borderColor};
    `
  }}
`)
