import React from 'react'
import sanitizeHtml from 'sanitize-html' // would prefer to use DOMPurify once we move to mono-repo

import { TextBlock } from '../../../../interfaces'
import { Wrapper } from './styles'
/**
 *
 */
export function BlockView({ block: { htmlText, text } }: { block: TextBlock }) {
  function createMarkup() {
    return {
      __html:
        (htmlText &&
          sanitizeHtml(htmlText, {
            // if elements are not specified here classes are removed
            allowedClasses: {
              p: ['*'],
            },
          })) ||
        '',
    }
  }

  return (
    <Wrapper alignment="center">
      {htmlText ? (
        <div dangerouslySetInnerHTML={createMarkup()} />
      ) : (
        <pre>{text}</pre>
      )}
    </Wrapper>
  )
}
