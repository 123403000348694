import React, { Props } from 'react'

import { useThemeContext } from '../context'

/**
 *
 */
const withTheme = (Component: React.ComponentType) => {
  const ComponentWithTheme = (props: any) => {
    const themeContext = useThemeContext()

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...themeContext} {...props} />
  }

  return ComponentWithTheme
}

export default withTheme
