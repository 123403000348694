import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import getFallbackImage from '../../utils/getFallbackImage'
import getResizeURL from '../../utils/getResizeUrl'

export const Image = styled.img<{
  width?: number
  height?: number
}>`
  border-radius: 100%;
  width: ${(props) => props.width || 30}px;
  height: ${(props) => props.height || 30}px;
  object-fit: cover;
`

/**
 *
 */
function Logo({
  url,
  alt,
  width,
  height,
  ...props
}: {
  url: string
  alt?: string
  width?: number
  height?: number
  props?: any
}) {
  const logoUrl = getResizeURL({ baseUrl: url, height, width })

  return (
    <Image
      width={width}
      height={height}
      src={logoUrl}
      alt={alt || 'Logo image'}
      onError={(event: SyntheticEvent<HTMLImageElement, Event>) =>
        getFallbackImage(event)
      }
      {...props}
    />
  )
}

export default Logo
