import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const PlaceholderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PostPlaceholder = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { secondaryBackgroundColor } = props.getStyles('FEED') || {}

    return css`
      height: 83px;
      width: 100%;
      background: ${secondaryBackgroundColor};
      border-radius: 9px;
    `
  }}
`)

export const PlaceholderMessage = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { corners, fontFamily, textColor, backgroundColor } =
      props.getStyles('FEED') || {}

    return css`
      position: absolute;
      width: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      background: ${backgroundColor};
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.04);
      border-radius: ${corners};
      padding: 30px 18px;

      p {
        color: ${textColor};
        font-family: ${fontFamily};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      b {
        color: ${textColor};
        font-family: ${fontFamily};
        font-weight: 700;
        font-size: 16px;
        line-height: 36px;
      }
    `
  }}
`)

export const Wrapper = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { corners, backgroundColor, secondaryBackgroundColor } =
      props.getStyles('FEED') || {}

    return css`
      margin: 16px 0px;
      border-radius: ${corners};
      text-align: center;
      background-color: ${backgroundColor};

      & > * {
        border-bottom: 1px solid ${secondaryBackgroundColor};
      }

      & > :last-child {
        border: none;
        border-radius: ${corners};
      }
    `
  }}
`)

export const Title = withTheme(styled.p<IThemeContext>`
  ${(props) => {
    const { fontFamily, textColor } = props.getStyles('FEED') || {}

    return css`
      text-align: left;
      font-family: ${fontFamily};
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: ${textColor};
      padding: 16px;
      margin: 0;
    `
  }}
`)
