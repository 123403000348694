/**
 * Start Page + Blocks
 */
export enum BlockType {
  HEADER = 'HEADER',
  TEXT = 'TEXT',
  SUB_HEADING = 'SUB_HEADING',
  BUTTON_LINK = 'BUTTON_LINK',
  IMAGE_LINK = 'IMAGE_LINK',
  VIDEO = 'VIDEO',
  ICON_LINKS = 'ICON_LINKS',
  SPOTIFY = 'SPOTIFY',
  IMAGE_LINKS_GRID = 'IMAGE_LINKS_GRID',
  FEED = 'FEED',
  FEED_POST = 'FEED_POST',
  MAILCHIMP = 'MAILCHIMP',
}

export type Media = {
  __typename?: string
  url: string
  altText: string
}

export interface Block {
  __typename: string
  id: string
  order: number
  hidden: boolean
  createdAt: Date
}

export interface LogoBlock extends Block {
  media: Media | null
}

export type SocialLink = {
  __typename?: string
  icon: string
  url: string
  order: number
}

export type ImageGridImage = {
  __typename?: string
  url: string | null
  media: Media | null
  order: number
  id?: string
}

export interface IconLinksBlock extends Block {
  links: SocialLink[] | null
}

export interface SubHeadingBlock extends Block {
  text: string
}

export interface TextBlock extends Block {
  text: string
  htmlText: string
  htmlJson: string
}

export interface LinkBlock extends Block {
  layout: 'TEXT' | 'IMG_TEXT'
  label: string
  url: string
  media: Media | null
}

export interface ImageLinkBlock extends Block {
  caption: string
  url: string
  media: Media | null
}

export interface VideoBlock extends Block {
  media: Media | null
}

export interface SpotifyBlock extends Block {
  media: Media | null
  layout: 'Compact' | 'Large'
}

export interface ImageLinksGridBlock extends Block {
  links: ImageGridImage[] | null
}

export interface FeedPostBlock extends Block {
  caption: string
  title: string
  text: string
  label: string
  url: string
  media: Media | null
  createdAt: Date
}

export interface FeedBlock extends Block {
  title: string
  children: FeedPostBlock[] | [] | null
}

export interface HeaderBlock extends Block {
  description: string
  title: string
  overlay: string | null
  backgroundMedia: Media | null
  logoMedia: Media | null
  layout: string
}

export interface MailchimpBlock extends Block {
  htmlText: string
}

export type AnyBlock =
  | LogoBlock
  | IconLinksBlock
  | SubHeadingBlock
  | TextBlock
  | LinkBlock
  | ImageLinkBlock
  | HeaderBlock
  | VideoBlock
  | FeedBlock
  | FeedPostBlock
  | MailchimpBlock

export type Blocks = Array<AnyBlock>

export interface GlobalStyles {
  backgroundColor: string
  textColor: string
}

export type BlockStyles = {
  blockType: string
  backgroundColor: string
  secondaryBackgroundColor?: string
  textColor: string
  secondaryTextColor?: string
  fontFamily: string
  secondaryFontFamily: string
  corners: string
  border: string
}

/**
 * Themes / Styles
 */
export interface IStartPageTheme {
  id: string
  globalStyles: GlobalStyles
  blockStyles: BlockStyles[]
}

export interface ButtonLinkStyles {
  blockType: string
  textColor: string
  fontFamily: string
  corners: string
  border?: string
}

export interface HeaderStyles {
  blockType: string
  backgroundColor: string
  textColor: string
  fontFamily: string
  secondaryFontFamily: string
  corners: string
  border?: string
}

export interface IconLinksStyles {
  blockType: string
  textColor: string
}

export interface ImageLinkStyles {
  blockType: string
  backgroundColor: string
  textColor: string
  fontFamily: string
  corners: string
  border?: string
}

export interface SubHeadingStyles {
  blockType: string
  backgroundColor: string
  textColor: string
  fontFamily: string
  corners: string
  border?: string
}

export interface TextStyles {
  blockType: string
  backgroundColor: string
  textColor: string
  fontFamily: string
  corners: string
  border?: string
}

export interface VideoStyles {
  blockType: string
  corners: string
  border?: string
}

export interface MailchimpStyles {
  blockType: string
  backgroundColor: string
  textColor: string
  fontFamily: string
  corners: string
  border?: string
}

export type StartPageData = {
  blocks?: Blocks
  domain?: string
  id?: string
  organizationId?: string
  hasUnPublishedChanges?: boolean
  __typename?: string
  error?: boolean
  theme?: IStartPageTheme
  title?: string
  isPublished?: boolean
}

export type StartPage = {
  startPage: StartPageData
  startPageV2: StartPageData
}
