import React from 'react'
import * as Icons from '@bufferapp/ui/Icon'
import { IconLinksBlock, SocialLink } from '../../../../interfaces'
import getAbsoluteUrl from '../../../../shared/utils/getAbsoluteUrl'
import validateEmail from '../../../../shared/utils/validateEmail'
import { CF_WORKER_TRACKING_URL } from '../../../../constants'
import { Wrapper, IconLinksWrapper } from './styles'

/**
 *
 */
function SingleIcon({
  icon,
  url,
  parent,
  pageId,
}: {
  icon: string
  url: string
  parent: string
  pageId?: string
}): JSX.Element {
  // @ts-ignore
  let NetworkIcon = Icons[icon]

  if (icon === 'Twitter') {
    NetworkIcon = Icons.X
  }

  const isEmail = !!validateEmail(url)

  const isMastodon = icon === 'Mastodon'

  let href: string
  if (isEmail) {
    href = `mailto:${url}`
  } else if (icon === 'Sms') {
    href = `sms:${url}`
  } else {
    href = getAbsoluteUrl(url)
  }

  return (
    <Wrapper>
      <a
        href={href}
        target="_blank"
        rel={`noopener noreferrer ugc${isMastodon ? ' me' : ''}`}
        aria-label={icon}
        ping={
          url ? `${CF_WORKER_TRACKING_URL}?pid=${pageId}&bid=${parent}` : ''
        }
      >
        {NetworkIcon && <NetworkIcon size="extraLarge" />}
      </a>
    </Wrapper>
  )
}

/**
 *
 */
export function BlockView({
  block,
  pageId,
}: {
  block: IconLinksBlock
  pageId?: string
}): JSX.Element {
  return (
    <IconLinksWrapper>
      {block?.links?.map((link: SocialLink, index: number) => (
        <SingleIcon
          {...link}
          key={block.__typename + index}
          parent={block.id}
          pageId={pageId}
        />
      ))}
    </IconLinksWrapper>
  )
}
