import React from 'react'

import Logo from '../../../../../../shared/components/Logo'
import { IHeaderView } from '../types'

import { Wrapper, Header, SubHeader } from './styles'

/**
 *
 */
function NoImageVertical({ logo, title, description, textColor }: IHeaderView) {
  const { image: logoImg, alt: logoAlt } = logo

  return (
    <Wrapper>
      {logoImg && <Logo url={logoImg} alt={logoAlt} width={100} height={100} />}
      {title && (
        <Header type="h1" color={textColor}>
          {title}
        </Header>
      )}
      {description && (
        <SubHeader type="h2" color={textColor}>
          {description}
        </SubHeader>
      )}
    </Wrapper>
  )
}

export default NoImageVertical
