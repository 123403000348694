import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { textColor } = props.getStyles('ICON_LINKS') || {}

    return css`
      a {
        color: ${textColor};
        padding: 8px 15px;

        :visited {
          color: ${textColor};
        }
      }
    `
  }}
`)

export const IconLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`
