import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { corners, border } = props.getStyles('VIDEO') || {}

    return css`
      border-radius: ${corners};
      border: ${border};
      margin-bottom: 16px;
    `
  }}
`)

export const Embed = styled.iframe<{ type?: string }>`
  width: 100%;
  height: ${(props) =>
    props.type && ['episode', 'show'].includes(props.type) ? '152px' : '80px'};
`
