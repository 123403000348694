/**
 * Inspired by: https://github.com/Gabb-c/spotify-embed/blob/main/src/utils/parser.ts
 */
const SpotifyEmbedTypes = [
  'track',
  'album',
  'playlist',
  'artist',
  'show',
  'episode',
]

type EmbedType = {
  type: string
  url: string
}

const getEmbed = (embedType: string, spotifyId: string): EmbedType => {
  if (!Object.values(SpotifyEmbedTypes).includes(embedType)) {
    throw new Error('Unsupported spotify url')
  }

  return {
    type: embedType,
    url: `https://open.spotify.com/embed/${embedType}/${spotifyId}`,
  }
}

const urlParser = (url: string): EmbedType => {
  const parsed = url.split('/')
  return getEmbed(parsed[3], parsed[4]?.split('?')[0])
}

const uriParser = (uri: string): EmbedType => {
  const parsed = uri.split(':')
  return getEmbed(parsed[1], parsed[2])
}

export function getSpotifyEmbed(src: string): EmbedType {
  if (src.length === 0 || !src.startsWith('spotify')) {
    return urlParser(src)
  }
  if (src.length === 0 || !src.startsWith('https://open.spotify.com/')) {
    return uriParser(src)
  }
  throw new Error('Unsupported spotify url')
}
