import styled from 'styled-components'

import { Header as BaseHeader, SubHeader as BaseSubHeader } from '../styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0px 32px 0px;
`

export const Header = styled(BaseHeader)`
  color: ${(props) => props.color};
  margin: 16px 0px 12px 0px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
`

export const SubHeader = styled(BaseSubHeader)`
  color: ${(props) => props.color};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
`
