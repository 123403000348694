/**
 * Snippet from: https://gist.github.com/takien/4077195#gistcomment-3410228
 */
export default function getYoutubeID(url: string) {
  const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)

  if (arr[0] !== url) {
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0]
  }

  return null
}
