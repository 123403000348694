import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import { ImageGridImage, ImageLinksGridBlock } from '../../../../interfaces'
import getAbsoluteUrl from '../../../../shared/utils/getAbsoluteUrl'

import {
  GridWrapper,
  EmptyWrapper,
  Link,
  Image,
  Placeholder,
  Message,
  MessageTitle,
  Text,
} from './styles'

export const PLACEHOLDER_COUNT = 9
export const IMAGE_LIMIT = 18
export const WARNING_TITLE = 'Image links grid'
export const WARNING_TEXT =
  'Please start by adding at least one image to your grid.'

/**
 *
 */
function LinksGrid({
  links,
}: {
  links: (ImageGridImage | null)[]
}): JSX.Element {
  return (
    <GridWrapper>
      {links.map((link, index) => {
        const { media, url } = link || {}
        const { url: imageUrl, altText } = media || {}

        return (
          <div key={index}>
            {url && media && (
              <Link
                key={index}
                href={getAbsoluteUrl(url)}
                target="_blank"
                rel="noopener noreferrer ugc"
              >
                {imageUrl && <Image src={imageUrl} alt={altText} />}
              </Link>
            )}
            {!url && imageUrl && <Image src={imageUrl} alt={altText} />}
            {!url && !media && <Placeholder key={index} />}
          </div>
        )
      })}
    </GridWrapper>
  )
}

/**
 *
 */
function EmptyGrid({
  preview = false,
}: {
  preview: boolean
}): JSX.Element | null {
  const placeholders = Array(PLACEHOLDER_COUNT).fill(null)

  return preview ? (
    <EmptyWrapper>
      <LinksGrid links={placeholders} />
      <Message>
        <WarningIcon size="large" />
        <MessageTitle>{WARNING_TITLE}</MessageTitle>
        <Text>{WARNING_TEXT}</Text>
      </Message>
    </EmptyWrapper>
  ) : null
}

/**
 *
 */
export function BlockView({
  block,
  preview = false,
}: {
  block: ImageLinksGridBlock
  preview: boolean
}): JSX.Element {
  return block.links && block.links.length > 0 ? (
    <LinksGrid links={block.links.slice(0, IMAGE_LIMIT)} />
  ) : (
    <EmptyGrid preview={preview} />
  )
}
