import React from 'react'

import getAbsoluteUrl from '../../../../shared/utils/getAbsoluteUrl'
import { LinkBlock } from '../../../../interfaces'
import { useThemeContext } from '../../../themes'
import { CF_WORKER_TRACKING_URL } from '../../../../constants'
import { Link } from './styles'

/**
 *
 */
export function BlockView({
  block: { label, url, id },
  pageId,
}: {
  block: LinkBlock
  pageId?: string
}): JSX.Element {
  const { getStyles } = useThemeContext()

  const { backgroundColor, textColor, corners, border, fontFamily } =
    getStyles('BUTTON_LINK')

  return (
    <Link
      customStyles={{
        backgroundColor,
        textColor,
        corners,
        border,
        fontFamily,
      }}
    >
      <a
        href={getAbsoluteUrl(url)}
        ping={url ? `${CF_WORKER_TRACKING_URL}?pid=${pageId}&bid=${id}` : ''}
        target="_blank"
        rel="noopener noreferrer ugc"
      >
        {label}
      </a>
    </Link>
  )
}
