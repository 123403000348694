import React from 'react'

import getResizeURL from '../../../../../../shared/utils/getResizeUrl'
import { IHeaderView } from '../types'

import {
  Wrapper,
  Background,
  ContentWrapper,
  Header,
  SubHeader,
  StyledLogo,
} from './styles'

/**
 *
 */
function ImageHalfVertical({
  backgroundImg,
  logo,
  title,
  description,
  textColor,
}: IHeaderView) {
  const { image: logoImg, alt: logoAlt } = logo

  const backgroundImgResized = backgroundImg
    ? getResizeURL({
        baseUrl: backgroundImg,
        width: 640,
        height: 200,
        fit: 'cover',
      })
    : null

  return (
    <Wrapper>
      <Background src={backgroundImgResized} />
      <ContentWrapper>
        {logoImg && (
          <StyledLogo url={logoImg} alt={logoAlt} width={100} height={100} />
        )}
        {title && (
          <Header type="h1" color={textColor}>
            {title}
          </Header>
        )}
        {description && (
          <SubHeader type="h2" color={textColor}>
            {description}
          </SubHeader>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

export default ImageHalfVertical
