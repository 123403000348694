import React from 'react'
import styled from 'styled-components'

import Website from '../Website'
import { Blocks } from '../../../../interfaces'
import { IThemeContext, ThemeProvider, withTheme } from '../../../themes'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const DeviceWrapper = withTheme(styled.div<IThemeContext>`
  width: 414px;
  height: 90%;
  overflow: scroll;
  background: ${(props) => props.getStyles()?.backgroundColor};
  box-sizing: border-box;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }
`)

/**
 *
 */
function Preview({
  blocks,
  theme,
  pageId,
  domain,
  isCommunityReportingFlagEnabled,
}: {
  blocks: Blocks
  theme: any
  pageId?: string
  domain?: string
  isCommunityReportingFlagEnabled?: boolean
}): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <DeviceWrapper>
          <Website
            blocks={blocks}
            pageId={pageId}
            preview
            domain={domain}
            isCommunityReportingFlagEnabled={isCommunityReportingFlagEnabled}
          />
        </DeviceWrapper>
      </Wrapper>
    </ThemeProvider>
  )
}

export default Preview
