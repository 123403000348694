import React from 'react'
import sanitizeHtml from 'sanitize-html'

import { MailchimpBlock } from '../../../../interfaces'
import { Wrapper } from './styles'

/**
 *
 */
export function BlockView({
  block: { htmlText },
}: {
  block: MailchimpBlock
}): React.JSX.Element {
  /**
   *  This function is used to sanitize the HTML text
   */
  function prepareHTML(htmlText: string): string {
    const decodedHTML = replaceHTMLEntities(htmlText)
    const htmlWithoutComments = removeComments(decodedHTML)
    return htmlWithoutComments
  }

  /**
   * This function is used to replace the HTML entities
   */
  function replaceHTMLEntities(str: string): string {
    // Replace &lt; with <
    str = str.replace(/&lt;/g, '<')

    // Replace &gt; with >
    str = str.replace(/&gt;/g, '>')

    return str
  }

  /**
   * This function is used to remove the comments from the HTML text
   */
  function removeComments(code: string): string {
    // Use regular expression to match and remove the comments
    const regex = /\/\*[\s\S]*?\*\//g
    return code.replace(regex, '')
  }

  function createMarkup(): { __html: string } {
    const allowedAttributes = {
      '*': ['id', 'class', 'style', 'aria-hidden', 'tabindex'], // Specify the global allowed attributes
      input: ['type', 'name', 'id', 'value'], // Specify the allowed attributes for <input> elements
      form: ['action', 'method', 'name', 'target', 'novalidate'], // Specify the allowed attributes for <form> elements
      label: ['for'], // Specify the allowed attributes for <label> elements
      a: ['href', 'title', 'target'], // Specify the allowed attributes for <a> elements
      img: ['src', 'alt', 'title', 'width', 'height'], // Specify the allowed attributes for <img> elements
      option: ['value'], // Specify the allowed attributes for <select> elements
    }
    return {
      __html:
        (htmlText &&
          sanitizeHtml(prepareHTML(htmlText), {
            allowedTags: [
              'form',
              'input',
              'label',
              'a',
              'fieldset',
              'div',
              'span',
              'p',
              'br',
              'img',
              'h2',
              'select',
              'option',
              'strong',
              'ul',
              'li',
              'ol',
            ],
            allowedAttributes,
            // if elements are not specified here classes are removed
            allowedClasses: {
              p: ['*'],
              div: ['*'],
              span: ['*'],
              form: ['*'],
              input: ['*'],
              label: ['*'],
              a: ['*'],
              fieldset: ['*'],
            },
          })) ||
        '',
    }
  }
  return (
    <Wrapper alignment="left">
      <div
        data-testid="sanitized-html"
        dangerouslySetInnerHTML={createMarkup()}
      />
    </Wrapper>
  )
}
