import styled, { css } from 'styled-components'
import Text from '@bufferapp/ui/Text'

import { withTheme } from '../../../../themes'

export const Header = withTheme(styled(Text)`
  ${(props) => {
    const { fontFamily } = props.getStyles('HEADER')

    return css`
      font-family: ${fontFamily};
      margin: 20px 0px;
      text-align: center;
    `
  }}
`)

export const SubHeader = withTheme(styled(Text)`
  ${(props) => {
    const { secondaryFontFamily } = props.getStyles('HEADER')

    return css`
      font-family: ${secondaryFontFamily};
      text-align: center;
    `
  }}
`)
