import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const Wrapper = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { corners, border } = props.getStyles('IMAGE_LINK') || {}

    return css`
      border-radius: ${corners};
      border: ${border};
      overflow: hidden;
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
      }
    `
  }}
`)

export const Caption = withTheme(styled.div<IThemeContext>`
  ${(props) => {
    const { backgroundColor, textColor, fontFamily } =
      props.getStyles('IMAGE_LINK') || {}

    return css`
      background-color: ${backgroundColor};
      padding: 16px;
      text-align: center;

      p {
        color: ${textColor};
        font-family: ${fontFamily};
        font-size: 16px;
      }
    `
  }}
`)
