export default function formatDate(rawDate: Date) {
  const date = new Date(rawDate)
  const now = new Date(Date.now())

  const monthStrs = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  let result = ''

  const d = date.getUTCDate()
  const m = monthStrs[date.getUTCMonth()]
  const y = date.getUTCFullYear()

  result += `${m} ${d}`

  if (y !== now.getFullYear()) {
    result += ` ${y}`
  }

  return result
}
