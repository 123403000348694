import React from 'react'

import { VideoBlock } from '../../../../interfaces'
import getYoutubeID from '../../../../shared/utils/getYoutubeID'
import { Wrapper } from './styles'

/**
 *
 */
export function BlockView({ block: { media } }: { block: VideoBlock }) {
  const videoID = media?.url && getYoutubeID(media?.url)

  return (
    <Wrapper>
      <iframe
        id="video-player"
        width="100%"
        src={
          videoID
            ? `https://www.youtube.com/embed/${videoID}?rel=0&modestbranding=1`
            : media?.url
        }
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Wrapper>
  )
}
