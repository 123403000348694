import styled, { css } from 'styled-components'

import { IThemeContext, withTheme } from '../../../themes'

export const Wrapper = withTheme(styled.div<
  {
    alignment: 'left' | 'center' | 'right'
  } & IThemeContext
>`
  ${(props) => {
    const { textColor, backgroundColor, corners, border, fontFamily } =
      props.getStyles('SUB_HEADING') || {}

    return css`
      color: ${textColor};
      background-color: ${backgroundColor};
      border-radius: ${corners};
      border: ${border};
      font-family: ${fontFamily};
      text-align: ${props.alignment};
      width: 100%;
      padding: 52px 20px;
      box-sizing: border-box;

      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        margin: 0;
      }
    `
  }}
`)
