import React from 'react'
import ArrowUp from '@bufferapp/ui/Icon/Icons/PercentageUp'

import { FeedPostBlock } from '../../../../interfaces'
import shortenText from '../../../../shared/utils/shortenText'
import getAbsoluteUrl from '../../../../shared/utils/getAbsoluteUrl'
import formatDate from '../../../../shared/utils/formatDate'
import { CF_WORKER_TRACKING_URL } from '../../../../constants'

import {
  Wrapper,
  ContentWrapper,
  Text,
  Image,
  Link,
  ReadMore,
  Date,
} from './styles'

export const handlers = [
  {
    className: 'read-more',
    eventName: 'click',
    handler: onReadMore,
  },
]

function onReadMore(event: React.KeyboardEvent | React.MouseEvent): void {
  const container = (event.target as HTMLElement).closest('div')
  if (!container) return

  const shortText = container.getElementsByClassName('short-text')[0]
  shortText.classList.toggle('hidden')

  const fullText = container.getElementsByClassName('full-text')[0]
  fullText.classList.toggle('hidden')
}

/**
 *
 */
export function BlockView({
  block,
  preview = false,
  pageId,
}: {
  block: FeedPostBlock
  preview: boolean
  pageId?: string
}): JSX.Element {
  const { text, label, url, media, id, createdAt } = block || {}

  const previewTextSize = media ? 100 : 200
  const textSize = preview ? previewTextSize : 300
  const shortenedText = shortenText(text, textSize)
  const showReadMore = shortenedText && shortenedText.length < text.length
  const formattedCreatedAt = formatDate(createdAt)

  return (
    <Wrapper>
      <ContentWrapper>
        {media?.url && <Image src={media.url} alt={media.altText} />}

        <Date>{formattedCreatedAt}</Date>

        {showReadMore && (
          <Text className="short-text">
            {shortenedText}
            <ReadMore className="read-more" onClick={onReadMore}>
              Read more
            </ReadMore>
          </Text>
        )}

        <Text className={`full-text ${showReadMore && 'hidden'}`}>{text}</Text>
      </ContentWrapper>
      {url && (
        <Link>
          <a
            href={getAbsoluteUrl(url)}
            target="_blank"
            rel="noopener noreferrer ugc"
            ping={`${CF_WORKER_TRACKING_URL}?pid=${pageId}&bid=${id}`}
          >
            {label || 'Find out more'}
            <ArrowUp size="medium" />
          </a>
        </Link>
      )}
    </Wrapper>
  )
}
