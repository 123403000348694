import * as ButtonLink from './components/ButtonLink'
import * as Header from './components/Header'
import * as IconLinks from './components/IconLinks'
import * as ImageLink from './components/ImageLink'
import * as SubHeading from './components/SubHeading'
import * as Text from './components/Text'
import * as Video from './components/Video'
import * as Spotify from './components/Spotify'
import * as ImageLinksGrid from './components/ImageLinksGrid'
import * as Feed from './components/Feed'
import * as FeedPost from './components/FeedPost'
import * as Mailchimp from './components/Mailchimp'

export const componentMap: {
  [index: string]: string
} = {
  StartPageBlockButtonLink: 'ButtonLink',
  StartPageBlockVideo: 'Video',
  StartPageBlockText: 'Text',
  StartPageBlockImageLink: 'ImageLink',
  StartPageBlockSubHeading: 'SubHeading',
  StartPageBlockHeader: 'Header',
  StartPageBlockIconLinks: 'IconLinks',
  StartPageBlockSpotify: 'Spotify',
  StartPageBlockImageLinksGrid: 'ImageLinksGrid',
  StartPageBlockFeed: 'Feed',
  StartPageBlockFeedPost: 'FeedPost',
  StartPageBlockMailchimp: 'Mailchimp',
}

export default {
  ButtonLink,
  Header,
  IconLinks,
  ImageLink,
  SubHeading,
  Text,
  Video,
  Spotify,
  ImageLinksGrid,
  Feed,
  FeedPost,
  Mailchimp,
}
